footer {
  background-color: var(--accent-color);
  color: white;
  text-align: center;
  margin-top: 50px;
  height: 60px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.footer-content a {
  color: white;
  text-decoration: none;
  font-weight: 500;
}
