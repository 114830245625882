.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 68px;
}

header {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 9999999;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoWrap {
  margin-left: 5px;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logoImg {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.logoInfo {
  color: #fff;
  font-size: 12px;
  margin-top: -4px;
}

.logo-text {
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin-left: 6px;
}

.beta-tag {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 5px;
}

nav ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

nav ul li {
  margin-left: 0;
  margin-right: 20px;
}

nav ul li a,
nav ul li div {
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

nav ul li a:hover,
nav ul li div:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.model-select,
.language-select {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}

.model-select select,
.language-select select {
  background: transparent;
  border: none;
  color: white;
  font-weight: 500;
  cursor: pointer;
  outline: none;
}

.model-select select option,
.language-select select option {
  background-color: var(--accent-color);
  color: white;
}

.user-icon {
  color: white;
  font-size: 20px;
}

.loginBtn {
  button {
    border-width: 2px;
    font-weight: bold;
  }
  button:hover {
    transform: scale(1.1);
    border-color: #fff !important;
    color: rgb(46, 203, 115) !important;
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
}

.login {
  padding-right: 10px;
  margin-top: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .user {
    height: 30px;
  }
  .totalQuota {
    padding-right: 10px;
    color: #010101;
  }
  .userInfo {
    color: #434343;
    display: none;
    position: absolute;
    right: 0;
    top: 24px;
    box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
    min-width: 150px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    padding: 8px 10px;
    box-sizing: border-box;
    > .quota {
      box-sizing: border-box;
      .quotaTitle {
        font-weight: bold;
        line-height: 30px;
        font-size: 16px;
      }
      .quotaContent {
        color: #595959;
        font-size: 14px;
        > div {
          margin-bottom: 4px;
          white-space: nowrap;
          display: flex;
          font-weight: 600;
          > span:nth-child(1) {
            font-weight: 600;
            width: 34px;
          }
          > div:nth-child(2) {
            min-width: 40px;
          }
          > div:nth-child(3) {
            margin-left: 10px;
            color: #8c8c8c;
            font-weight: 400;
            font-size: 12px;
          }
          &:nth-child(2) {
            font-weight: initial;
          }
        }
      }
    }
    > .updatePassword {
      margin-bottom: 6px;
    }
  }
  &:hover {
    > .userInfo {
      display: block !important;
    }
  }
}
