body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --accent-color: #34495e;
  --light-bg: #f5f9fc;
  --card-bg: #ffffff;
  --text-color: #333333;
  --border-color: #e0e0e0;
}
