.price {
  background: linear-gradient(to top, #eef2ff, #fff, #cffafe);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 40px 20px;
}

.container2 {
  min-height: calc(100vh - 110px - 68px);
}

.header {
  text-align: center;

  margin-bottom: 40px;
}

.title {
  margin: 0;

  font-size: 2.5rem;

  font-weight: 700;

  background: linear-gradient(90deg, #3182ce, #38b2ac);

  background-clip: text;
  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;

  padding-bottom: 10px;
}

.subtitle {
  font-size: 1.8rem;

  color: #2c5282;

  margin-top: 10px;
}

.section-title {
  text-align: center;

  font-size: 1.5rem;

  color: #2c5282;

  margin: 30px 0;

  position: relative;
}

.section-title:after {
  content: '';

  position: absolute;

  bottom: -10px;

  left: 50%;

  transform: translateX(-50%);

  width: 100px;

  height: 3px;

  background: linear-gradient(90deg, #3182ce, #38b2ac);
}

.plans-container {
  display: flex;

  justify-content: center;

  gap: 24px;

  flex-wrap: wrap;

  margin-bottom: 50px;
}

.plan-card {
  background-color: white;

  border-radius: 8px;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  padding: 30px;

  width: 280px;

  transition: transform 0.3s ease, box-shadow 0.3s ease;

  display: flex;

  flex-direction: column;

  position: relative;

  border-top: 4px solid #3182ce;
}

.plan-card:hover {
  transform: translateY(-5px);

  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.plan-card.featured {
  border-top: 4px solid #38b2ac;
}

.featured-badge {
  position: absolute;

  top: -12px;

  right: 20px;

  background: #38b2ac;

  color: white;

  padding: 5px 15px;

  border-radius: 20px;

  font-size: 0.8rem;

  font-weight: bold;
}

.feature-item {
  display: flex;

  align-items: center;

  margin-bottom: 16px;

  font-size: 1rem;
}

.check-icon {
  color: #38b2ac;

  margin-right: 10px;

  display: inline-flex;

  align-items: center;

  justify-content: center;

  width: 24px;

  height: 24px;

  border-radius: 50%;

  background-color: rgba(56, 178, 172, 0.1);
}

.feature-text {
  color: #1a202c;

  font-weight: 500;
}

.feature-value {
  font-weight: 700;

  color: #2c5282;

  margin-left: 5px;
}

.plan-button {
  margin-top: auto;

  background: linear-gradient(90deg, #3182ce, #38b2ac);

  border: none;

  color: white;

  padding: 14px 20px;

  border-radius: 8px;

  font-weight: 600;

  cursor: pointer;

  transition: opacity 0.3s ease;

  font-size: 1rem;

  text-align: center;

  display: block;

  text-decoration: none;
}

.plan-button:hover {
  opacity: 0.9;
}

.plan-title {
  font-size: 1.5rem;

  color: #2c5282;

  margin-bottom: 20px;

  font-weight: 700;

  text-align: center;
}

.plan-price {
  font-size: 2rem;

  color: #2c5282;

  font-weight: 700;

  margin-bottom: 5px;

  text-align: center;
}

.price-period {
  font-size: 0.9rem;

  color: #64748b;

  margin-bottom: 20px;

  text-align: center;
}

.tab-container {
  display: flex;

  justify-content: center;

  margin-bottom: 30px;
}

.tab {
  padding: 10px 30px;

  background-color: white;

  border: 1px solid #e2e8f0;

  cursor: pointer;

  font-weight: 600;

  transition: all 0.3s ease;
}

.tab:first-child {
  border-radius: 8px 0 0 8px;
}

.tab:last-child {
  border-radius: 0 8px 8px 0;
}

.tab.active {
  background: linear-gradient(90deg, #3182ce, #38b2ac);

  color: white;

  border-color: transparent;
}

.savings-tag {
  background-color: #f87171;

  color: white;

  padding: 3px 8px;

  border-radius: 4px;

  font-size: 0.7rem;

  font-weight: bold;

  position: absolute;

  top: 10px;

  right: 10px;
}

.footer {
  text-align: center;

  margin-top: 40px;

  font-size: 0.9rem;

  color: #64748b;

  padding: 20px;

  border-top: 1px solid #e2e8f0;
}

.value-prop {
  font-size: 0.9rem;

  color: #64748b;

  margin-top: 8px;

  margin-bottom: 24px;

  font-style: italic;

  text-align: center;
}
