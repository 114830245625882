.upload-container {
  background-color: var(--card-bg);
  border-radius: 10px;
  padding: 30px;
  margin: 30px 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.file-type-selector {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: nowrap;
  gap: 20px;
  width: 100%;
}

.file-type-selector > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.file-type-selector label {
  font-size: 14px;
  color: var(--accent-color);
  font-weight: 500;
}

.file-type-selector .select {
  flex: 1;
  margin-left: 8px;
}

.inputBox {
  display: flex;

  .upload-area {
    height: 220px;
    flex: 1;
  }

  .img {
    margin-left: 20px;
    width: 500px;
    height: 220px;
    object-fit: contain;
  }
}

.dropdownRender{
  position: relative;

  .tag{
    position: absolute;
    right: 0;
    bottom: 4px;
    cursor: pointer;
  }
}