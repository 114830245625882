body {
  background-color: var(--light-bg);
  color: var(--text-color);
  line-height: 1.6;
  min-height: 100vh;
}

.indexBox {
  box-sizing: border-box;
}

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.container2 {
  min-height: calc(100vh - 110px - 98px);
}

.hero {
  text-align: center;
  padding: 50px 0 30px;
}

.hero h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero h1 span:first-child {
  color: var(--accent-color);
}

.hero h1 .free {
  color: var(--primary-color);
}

.hero h1 .alternative {
  color: var(--secondary-color);
}

.adsbygoogle {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}
